import { createApp, defineAsyncComponent } from "vue";

import SvgVue from 'svg-vue3'
import VueLazyLoad from 'vue3-lazyload'

const app = createApp({
  components: {
    Slider: defineAsyncComponent(() => import('./common/Slider.vue')),
    SocialIcons: defineAsyncComponent(() => import('./common/SocialMediaIcons.vue')),
    VideoFeed: defineAsyncComponent(() => import('./common/VideoFeed.vue')),
  },

  data: () => ({
    openMenu: '',
    scrollPosition: 0,
    alertsOpen: false
  }),

  computed: {
    mobileMenu () {
      return this.openMenu === 'mobileMenu'
    },
    howDoI () {
      return this.openMenu === 'howDoI'
    },
    searchBox () {
      return this.openMenu === 'searchBox'
    },
    isScrolling () {
      return this.scrollPosition > 0
    },
  },

  mounted () {

    if (localStorage.popup === true || !('popup' in localStorage)) {
      this.popupOpen = true
    } else {
      this.popupOpen = false
    }

  },

  methods: {
    toggleMenu (menu) {
      this.openMenu = (this.openMenu != menu ? menu : '')
    },

    handleScroll (event) {
      this.scrollPosition = window.pageYOffset != undefined ? window.pageYOffset : window.scrollY
    },

    setSidebar (sidebar) {
      this.openSidebar = sidebar
    },

    closePopup (ids) {
      this.popupOpen = false
      localStorage.popup = false
      localStorage.saved = new Date().getTime()
      localStorage.silenced = ids
    },

    checkPopUp (popupIds) {
      this.checkPopupTimeout()
      let silenced = localStorage.getItem('silenced')
      popupIds.forEach(element => {
        if(silenced && !silenced.includes(element)){
          this.popupOpen = true
        }
      });
      return this.popupOpen
    },

    checkPopupTimeout () {
      let now = new Date().getTime()
      let time = 24 * 60 * 60 * 1000 // 60000 milliseconds = 1 minute
      let saved = localStorage.getItem('saved')

      if (saved && ((now - saved) > time)) {
        localStorage.popup = true
        this.popupOpen = true
      }
    },

  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

})

app.use(VueLazyLoad)
app.use(SvgVue, {
  svgPath: 'resources/svg',
  extract: false,
  svgoSettings: [
      { removeTitle: true },
      { removeViewBox: false },
      { removeDimensions: true }
  ]
})

app.mount("#app")
